import React, { Component } from 'react';
import data from './data/data.json';
class App extends Component {
  render() {
    return (
      <div>
        <button type="button" className="mobile-nav-toggle d-xl-none"><i className="icofont-navigation-menu"></i></button>


        <header id="header" className="d-flex flex-column justify-content-center">

          <nav className="nav-menu">
            <ul>
              <li className="active"><a href="#hero"><i className="bx bx-home"></i> <span>Home</span></a></li>
              <li><a href="#about"><i className="bx bx-user"></i> <span>About</span></a></li>
              <li><a href="#Gallery"><i className="bx bx-file-blank"></i> <span>Gallery</span></a></li>
              <li><a href="#Video"><i className="bx bx-book-content"></i> <span>Video</span></a></li>
              <li><a href="#PressRelease"><i className="bx bx-server"></i> <span>Press Release</span></a></li>
              <li><a href="#contact"><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
            </ul>
          </nav>

        </header>
        <section id="hero" className="d-flex flex-column justify-content-center">
          <div className="container" data-aos="zoom-in" data-aos-delay="100">
            <h1>Rangarajan N</h1>
            <p>I'm <span id="typed" data-typed-items="Designer, Developer, Freelancer, Photographer"></span></p>
            <div className="social-links">
              <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
              <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
              <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
              <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
              <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
            </div>
            <div className="row home_abt_his pt-4">
              <div className="col-md-4">
                <h2 className="cus_head_1">Objective</h2>
                <p>
                  To work with organization where I can use my potential in order to achieve my career goals and also enhance company's statistics with my hard work and dedication.
              </p>
              </div>
              <div className="col-md-4">
                <h2 className="cus_head_1">Achievement</h2>
                <p>
                  Suggest solution for Government Projects, (e-gov) like RTO ( Digitalization ofDriving licenses & RC Books) PDS ( Puducherry).
              </p>
              </div>
              <div className="col-md-4">
                <h2 className="cus_head_1">Education</h2>
                <p>
                  MBA (Marketing) from Alagappa University. M.Phil (Management) Alagappa university MA Economics from University of Madras.
              </p>
              </div>
            </div>
          </div>
        </section>

        <main id="main">


          <section id="about" className="about">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>About</h2>
                <p>If one wants to sell and market sand in the desert or oil in the middle-east then the man one needs to look for is undoubtedly Mr. N. Rengarajan alias Renga. With over two decades of rich experience in Sales & Marketing across varied industry and diverse product range. </p>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <img src="assets/img/profile-img.jpg" className="img-fluid" alt="" />
                </div>
                <div className="col-lg-8 pt-4 pt-lg-0 content">
                  <p className="font-italic">
                    From selling space to develop farms to marketing space on cyberspace to develop websites, Renga has carved a niche for himself in this profession.

                    He launched his career as a fresh Sales Executive in the year 1990 for a company called ‘Visiba Farms’ selling farm lands in Chennai to clients from India and abroad. He also worked at the same time as Sales Representative for Indian Tarpaulin Industries from 1990 to 1992.

                    For the next 4 years from 1995 to 1999 he moved into the most difficult segment of marketing which is Concept Selling marketing farmlands for a company called ‘Green Country Agro Foods Ltd’.

                    During that period as a ‘Zonal Manager’ he was in charge of team which comprised of over 60 sales executive and was in charge of 3 states in India. He played a pivotal role in the rapid growth of the company and through sheer persuasion he got many corporate tie up’s which includes even Fortune 500 global corporations.

                    He worked for ‘Alsa Real Estate’ as a General Manager responsible for marketing their luxury condominiums and shopping malls.

                    He made his foray in the Information Technology Industry in 2000 and he started marketing domain space for a ‘Reliable Systems’ who were channel partners for an ISP called ‘NET 4 India’.

                    With a strong belief that Internet will play a dominant role in the future and the only way one can penetrate the markets in India is by developing portals which are based on languages from India like Thamizh, Hindi, Telugu, Kannada, Malayalam and 90 others languages which are spoken in India.


            </p>

                </div>
              </div>

              <p className="pt-3">
                He joined ‘Webdunia.com India Pvt Ltd’ he was responsible for marketing language and advertising solutions for big organizations which includes the Government of Thamizh Nadu as one his clients in his esteemed clientele list.

                Currently Renga is a Director of a multimedia animation company called ‘L TECK MEDIA Private Limited’, a Chennai based company which specializes in e-learning and multimedia solutions.

                Renga also has an MBA (Marketing) from Alagappa University.M.Phil( Management) Alagappa University, MA (Economics) from University of Madras, PG Diploma in Marketing Management from Annamalai University, Professional Selling skills from NIS.

                He is also the Executive Committee Member for Kanithamizh Sangam, he is also a consultant for E-Governance for making and implementing policy for various organizations.
              </p>

            </div>
          </section>





          <section id="Gallery" className="portfolio section-bg">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Gallery</h2>
              </div>



              <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                {
                  data.Gallery.map((GalleryImage, i) => {
                    return (
                      <div className="col-lg-4 col-md-6 portfolio-item filter-app" key={i}>
                        <div className="portfolio-wrap">
                          <img src={`assets/img/${GalleryImage.ImageName}.jpg`} className="img-fluid" alt="" />
                          <div className="portfolio-info">
                            <div className="portfolio-links">
                              <a href={`assets/img/${GalleryImage.ImageName}.jpg`} data-gall="portfolioGallery" className="venobox" title="Gallery">
                                <i className="bx bx-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );

                  })
                }
              </div>
            </div>
          </section>

          <section id="Video" className="portfolio section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Video</h2>
              </div>
              <div className="row VideoRow" data-aos="fade-up" data-aos-delay="200">
                {
                  data.Video.map((VideoGet, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        <iframe src={VideoGet.VideoURL} allowFullScreen></iframe>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </section>

          <section id="PressRelease" className="portfolio section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Press Release</h2>
              </div>
              <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                {
                  data.PressRelease.map((Press, i) => {
                    return (
                      <div className="col-lg-4 col-md-6 portfolio-item filter-app" key={i}>
                        <div className="portfolio-wrap">
                          <img src={`assets/img/${Press.PressImg}.jpg`} className="img-fluid" alt="" />
                          <div className="portfolio-info">
                            <div className="portfolio-links">
                              <a href={`assets/img/${Press.PressImg}.jpg`} data-gall="portfolioGallery" className="venobox" title="Press Release">
                                <i className="bx bx-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );

                  })
                }
              </div>
            </div>
          </section>





          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Contact</h2>
              </div>

              <div className="row mt-1">

                <div className="col-lg-4">
                  <div className="info">
                    <div className="address">
                      <i className="icofont-google-map"></i>
                      <h4>Location:</h4>
                      <p>Ashok Nagar, Chennai</p>
                    </div>

                    <div className="email">
                      <i className="icofont-envelope"></i>
                      <h4>Email:</h4>
                      <p> kanchi_ranga@yahoo.com</p>
                    </div>

                    <div className="phone">
                      <i className="icofont-phone"></i>
                      <h4>Call:</h4>
                      <p>+91 944 443 3077</p>
                      <p> +91 936 112 9292</p>
                    </div>

                  </div>

                </div>

                <div className="col-lg-8 mt-5 mt-lg-0 google_map">

                  {
                    data.GoogleMap.map((GoogleLoc, i) => {
                      return (
                        <iframe key={i} allowFullScreen="" aria-hidden="false" tabIndex="0" src={GoogleLoc.GoogleURL}></iframe>
                      )
                    })
  }
                </div>

              </div>

            </div>
          </section>

        </main>


        <footer id="footer">
          <div className="container">
            <div className="social-links">
              <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
              <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
              <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
              <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
              <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
            </div>
            <div className="copyright">
              &copy; Copyright <strong><span>Rangarajan N</span></strong>. All Rights Reserved
      </div>
            <div className="credits">
              Designed by <a href="https://webszilla.com/" target="_blank">webszilla</a>
            </div>
          </div>
        </footer>

        <a href="#" className="back-to-top"><i className="bx bx-up-arrow-alt"></i></a>
        <div id="preloader"></div>
      </div>
    );
  }
}

export default App;
